<template>

  <div>
    <!-- Filters -->
    <list-filters
        @emitOnchangeJenisKerjasama="onChangeJenisKerjasama"
        @emitLoadFilter="loadFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        style="text-align: center;"
      >

        <template #cell(laporan_pelapor)="data">
          <div v-for="datax in data.item.laporan_pelapor"
            :key="datax.id"
          >
            {{datax.nama}}
          </div>
        </template>

        <template #cell(perihal)="data">
          <div v-html="data.item.perihal"></div>
        </template>

        <template #cell(laporan_terlapor)="data">
          <div v-for="datax in data.item.laporan_terlapor"
            :key="datax.id"
          >
            {{datax.nama}}
          </div>
        </template>

        <template #cell(status)="data">
          <div v-if="data.item.status_investigasi !== null">
            <b-badge pill :variant="`light-success`">{{ data.item.status_investigasi }}</b-badge>
          </div>
           <div v-else>
            <b-badge pill :variant="`light-warning`">Pengajuan</b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <div class="d-flex align-items-center col-actions">
              <b-link  title="Lihat Detail" :to="{ name: 'detail-investigasi-advokasi', params: { laporan_id: data.item.id } }" class="me-1 mr-1"><feather-icon icon="EyeIcon" style="width: 20px; height: 20px;"/></b-link>
            </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/investigasi-advokasi/storeModule'
import useList from '@/connection-api/investigasi-advokasi/list'
import listAddNew from './ListAddNew.vue'
import listFilters from './ListFilters.vue'
import ModalDokumen from './ModalDokumen.vue'
import {
  deleteKerjasama,
  pilihNotaKesepahaman,
  pilihPembahasanKerjasama,
} from '@/connection-api/laporan'
// import {
//   createUpdateJadwalPembahasan,
// } from '@/connection-api/jadwal-pembahasan'
import {
  // getJenisKerjasama,
  getJenisPihakTerlibat,
  getJenisDokumen,
  // getNamaPengusul,
} from '@/connection-api/master'

export default {
  components: {
    listFilters,
    listAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BBadge,
    BLink,

    vSelect,
    ModalDokumen,
  },
  data() {
    return {
      localData: {
        jenis_kerjasama_id: null,
        jenis_pihak_terlibat_id: null,
        jenis_dokumen_id: null,
      },
      jenisKerjasamaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kerja Sama --' },
        ],
      },
      jenisPihakTerlibatOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Pihak Terlibat --' },
        ],
      },
      jenisDokumenOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      namaPengusulOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Nama Pengusul --' },
        ],
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async mounted() {
    if(this.$route.params.jadwal_pembahasan_id)
    {
      this.JadwalPembahasanId = this.$route.params.jadwal_pembahasan_id
    }

    if(this.$route.params.id)
    {
      this.kerjasamaId = this.$route.params.id
    }

    if(this.$route.params.tipe)
    {
      this.tipeKerjasama = this.$route.params.tipe
    }
    this.statusLaporanId = "Verifikasi"

    // const responseJenisKerjasama = await getJenisKerjasama()
    // this.mappingSelectOption(responseJenisKerjasama.data, this.jenisKerjasamaOptions.options)

    // const responsePengusul = await getNamaPengusul()
    // this.mappingSelectOption(responsePengusul.data, this.namaPengusulOptions.options)
  },
  methods: {
    // async createOrUpdateJadwalPembahasan(kerjasamaId){
    //   const data = {
    //     kerjasama_id: kerjasamaId,
    //   }

    //   const response = await createUpdateJadwalPembahasan(data)
    //   if(response.data.success === true){
    //      this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Kerjasama',
    //         icon: 'CoffeeIcon',
    //         variant: 'success',
    //         text: 'Data berhasil disimpan',
    //       },
    //     })
    //     if(response.data.data.start === 1) {
    //       setTimeout(() => {
    //       this.$router.push({name: 'dalam-pembahasan-detail', params: {jadwal_pembahasan_id: response.data.data.id, kerjasama_id: response.data.data.kerjasama_id}})
    //     }, 2000);
    //     } else {
    //       setTimeout(() => {
    //       this.$router.push({name: 'jadwal-pembahasan-edit', params: {jadwal_pembahasan_id: response.data.data.id}})
    //     }, 2000);
    //     }
    //   } 
    // },
    loadFilter(data){
      this.changer += 1
      this.filterData = data
    },
    async onChangeJenisKerjasama(jenisKerjasamaId) {
      if(jenisKerjasamaId !== null)
      {
         this.jenisPihakTerlibatOptions = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Jenis Pihak Terlibat --' },
            ],
          }
          const responseJenisPihakTerlibat = await getJenisPihakTerlibat(jenisKerjasamaId)
          this.mappingSelectOption(responseJenisPihakTerlibat.data, this.jenisPihakTerlibatOptions.options)

          this.jenisDokumenOptions = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Jenis Dokumen --' },
            ],
          }
          const responseJenisDOkumen = await getJenisDokumen(jenisKerjasamaId)
          this.mappingSelectOption(responseJenisDOkumen.data, this.jenisDokumenOptions.options)
      }
    },
    async pilihNotaKesepahaman(id) {
      const data = {
        nota_kesepahaman_terkait_id: id,
        kerjasama_id: this.$route.params.id,
      }
      const resp = await pilihNotaKesepahaman(data)
      if(resp.data.success === true)
      {
         this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Kerjasama',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil dipilih',
          },
        })
        this.refetchData()
      }
    },
    async pilihPembahasanKerjasama(id) {
      const data = {
        id: id,
        jadwal_pembahasan_id: this.$route.params.jadwal_pembahasan_id
      }
      const resp = await pilihPembahasanKerjasama(data)
      if(resp.data.success === true)
      {
         this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Kerjasama',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil dipilih',
          },
        })
        this.refetchData()
        this.$emit('emitReloadData')
      }
    },
    async deleteData(id) {
        const data = {
        id: id
        }
        const resp = await deleteKerjasama(data)
        if(resp.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kerjasama',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil di hapus',
              },
            })
            this.refetchData()
        }
    },
     mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'investigasi-advokasi'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      filterData,
      changer,
      laporanId,
      statusLaporanId,

    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      filterData,
      changer,
      laporanId,
      statusLaporanId,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
